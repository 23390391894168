// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$custom-theme-colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "gray": $gray-500,
    "black": $black,
    "white": $white,
    "shift-blue": shift-color($blue, $table-bg-scale),
    "shift-indigo": shift-color($indigo, $table-bg-scale),
    "shift-purple": shift-color($purple, $table-bg-scale),
    "shift-pink": shift-color($pink, $table-bg-scale),
    "shift-red": shift-color($red, $table-bg-scale),
    "shift-orange": shift-color($orange, $table-bg-scale),
    "shift-yellow": shift-color($yellow, $table-bg-scale),
    "shift-green": shift-color($green, $table-bg-scale),
    "shift-teal": shift-color($teal, $table-bg-scale),
    "shift-cyan": shift-color($cyan, $table-bg-scale),
    "shift-gray": shift-color($gray-500, $table-bg-scale),
    "shift-black": shift-color($black, $table-bg-scale),
    "shift-white": shift-color($white, $table-bg-scale),
);

$theme-colors: map-merge($theme-colors, $custom-theme-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
$table-variants: map-merge($table-variants, $custom-theme-colors);


// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-icons/font/bootstrap-icons';

@import '~jquery-ui/themes/base/all.css';
